.app {
  text-align: left;
}

@font-face {
  font-family: IowanOldStyle;
  src: url("assets/fonts/IowanOldStyleBT-Roman.otf") format("opentype");
}

/* @font-face {
  font-family: abc;
  src: url("assets/fonts/inconsolata.tff") format("truetype-variations");
} */

a:hover {
  color: #3FDCFF;
}

a {
  color: #C0C0C0;
  text-decoration: none
}

ul li { padding: 5px 0px; }


.title {
  padding-left: '100px';
  font-size: '80px'
}

.left-container {
  min-width: 55vw;
  display: flex;
  flex-direction: column;
}

.right-container {
  min-width: 45vw;
  display: flex;
  flex-direction: column;
}

.app-body {
  background-color: #0C0C0C;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: left;
  color: #6B6B6B;
}



.app-link {
  color: #61dafb;
}
